$main-width: 512px;

:root {
    color-scheme: light dark;
    --body-background-colour: #ffffff;
    --body-colour: #24292e;
    --post-title-colour: black;
    --quote-background-colour: #bbe6ff;
    --quote-border-colour: #000000;
    --sumary-font-colour: #9C9C9C;
    --group-header-font-colour: #9C9C9C;
    --tag-background-colour: #D8D8D8;
    --tag-font-colour: #454545;
    --highlight-background-colour: whitesmoke;
    --header-font-colour: #5e5d5d;
    --header-h2-font-colour: #24292e;
    --table-header-background-colour: whitesmoke;
    --table-header-underline-colour: black;
    --table-border-colour: #ddd;
    --table-border-raidus: 8px;
    --link-unvisited-colour: #8888ff;
}

@media (prefers-color-scheme: dark) {
    img[src$=".svg"] {
        filter: invert(100%) sepia(0%) saturate(7464%) hue-rotate(149deg) brightness(96%) contrast(107%);
    }

    :root {
        --body-background-colour: #232323;
        --body-colour: #ebebeb;
        --header-h2-font-colour: #ebebeb;
        --post-title-colour: white;
        --quote-background-colour: inherit;
        --quote-border-colour: #818181;
        --sumary-font-colour: rgb(172, 172, 172);
        --group-header-font-colour: #9C9C9C;
        --tag-background-colour: #727272;
        --tag-font-colour: rgb(228, 228, 228);
        --highlight-background-colour: #3b3b3b;
        --header-font-colour: #949494;

        --table-header-background-colour: rgb(46, 46, 46);
        --table-header-underline-colour: rgb(224, 224, 224);
        --table-border-colour: rgb(110, 110, 110);
        --link-unvisited-colour: #d4d4ff
    }

    a:visited {
        color: #a9a9ff;
    }

    .rouge-table {
        background-color: var(--quote-background-colour);
    }
}

html,
body {
    background-color: var(--body-background-colour);
}
/* Main Content */
body {
    font-family: apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";    
    padding-bottom: 48px;
    color: var(--body-colour);

    > *:not(.post-container),
    .post-container > *:not(.image-container) {
        margin-left: auto;
        margin-right: auto;
        max-width: 768px;
    }

    &.tags-page {
        padding-bottom: 90vh;
    }
}

/* Reset figure & pre so they don't have their
   default margins making spacing look off */
figure,
pre {
    margin: 0;
    line-height: initial;
}

h1, h2, h3, h4, h5, h6 {
    margin-left: 0px;
    margin-top: 16px;
    margin-right: 0px;
    margin-bottom: 4px;

    + p {
        margin-top: 0px;
    }
}

h2 {
    font-size: 1.3em;
}

blockquote {
    border-style: none none none solid;
    border-width: 4px;
    border-color: transparent;
    border-left-color: var(--quote-border-colour);
    padding-left: 12px;
    margin-left: 0px;
    padding-top: 4px;
    padding-bottom: 4px;
}

blockquote.post-note {
    background-color: var(--highlight-background-colour);
    color: var(--header-font-colour);
    font-size: 0.9em;
}

table {
    transform: translateX(-10px);
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px;
    text-align: start;

    th {
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: var(--table-header-underline-colour);
        background-color: var(--table-header-background-colour);
        text-align: start;
    }

    td {
        border-width: 1px;
        border-style: solid;
        border-color: var(--table-border-colour);
    }

    td, th {
        padding: 12px;
    }

    tr td {
        border-top-width: 0px;
        border-left-width: 1px;
        border-right-width: 0px;
    }

    tr td:last-child {
        border-right-width: 1px;
    }

    tr:last-child td:first-child {
        border-bottom-left-radius: var(--table-border-raidus);
    }

    tr:last-child td:last-child {
        border-bottom-right-radius: var(--table-border-raidus);
    }

    tr:first-child th:first-child {
        border-top-left-radius: var(--table-border-raidus);
    }

    tr:first-child th:last-child {
        border-top-right-radius: var(--table-border-raidus);
    }
}

a:link {
    color: var(--link-unvisited-colour);
}

.rouge-table .gutter.gl {
    width: 24px;

    .lineno {
        width: auto;
    }
}

header {
    line-height: initial;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
    h1 {
        font-weight: 100;
        font-size: 36px;
        margin-bottom: 16px;
    }

    img {
        height: 14px;
        align-self: flex-start;
    }
}

summary {
    line-height: 145%;
    font-style: oblique;
    color: var(--sumary-font-colour);
    margin-top: 0px;
    margin-bottom: 12px;
}

article {
    font-size: 16px;
    padding-bottom: 28px;
}

footer {
    font-size: 14px;

    img {
        transform: translateY(2px);
        height: 14px;
        margin: 0px 2px;
    }
}

.post-navigation {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 12px;

    > a.nav-previous {
        grid-column: 1;
        justify-self: start;
    }

    > a.nav-next {
        grid-column: 2;
        justify-self: end;
    }
}

p {
    margin-top: 16px;
    margin-bottom: 16px;
}

p:last-child {
    margin-top: 28px;
    margin-bottom: 0px;
}

p:first-child {
    margin-top: 0px;
    margin-bottom: 12px;
}

blockquote > p:first-child {
    margin-top: 4px;
    margin-bottom: 4px;
}

pre,
code {
    font-size: 15px;
    white-space: pre-wrap;
}

code.highlighter-rouge {
    font-family: "Code", monospace;
    background-color: var(--highlight-background-colour);
    padding: 2px 8px;
    border-radius: 2px;
}

.group-container {
    width: 100%;
    padding-bottom: 8px;
}

.group-display {
    width: 150px;
    box-sizing: border-box;
    padding-right: 12px;
    padding-bottom: 4px;
    color: var(--group-header-font-colour);
    font-size: 24px;
    font-weight: bold;
}

@media only screen and (min-width: 900px) {
    .group-display {
        position: absolute;
        margin-left: -162px;
        text-align: end;
        transform: translateY(-4px);
    }

    .article-page .article-page-home-link {
        margin-left: -40px;
        margin-right: 24px;
        margin-top: 24px;

        /* Shuffle the home icon down a little */
        > img {
            margin-top: 4px;
        }
    }

    .group-container,
    .article-page > header {
        display: flex;
        flex-direction: row;
    }
}

.group-posts {
    width: 100%;
}

.article-page > header > h1 {
    width: 100%;
}

a.post-title {
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
    color: var(--post-title-colour);
}

.post-summary {
    font-size: 16px;
    padding-bottom: 24px;
}

.post-summary:last-child {
    padding-bottom: 28px;
}

.post-details {
    font-weight: 100;
    padding-top: 8px;
    padding-bottom: 4px;
    font-size: 16px;
}

.post-excerpt {
    line-height: 150%;

    summary {
        line-height: 145%;
        margin-bottom: 3px;
    }
}

.post-tags {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    
    .post-tag {
        background-color: var(--tag-background-colour);
        color: var(--tag-font-colour);
        margin-right: 8px;
        padding: 6px 8px;
        border-radius: 4px;

        > a {
            text-decoration: none;
            color: inherit;
        }
    }
}

.post-container {
    line-height: 150%;

    img {
        max-width: 100%;
    }

    > .highlight,
    > .highlighter-rouge {
        background-color: var(--highlight-background-colour);
        padding: 8px;
        border-radius: 4px;
    }

    h1, h2, h3, h4, h5, h6, h7 {
        font-weight: 800;
        color: var(--header-font-colour);
        line-height: initial;
    }

    h1 {
        font-size: 2em;
        margin-bottom: 0.5em;
    }
    h2 {
        font-size: 1.5em;
        margin-bottom: 0.2em;
        color: var(--header-h2-font-colour);
    }
    h3 { font-size: 1.25em; }
    h4 { font-size: 1.0em; }
    h5 { font-size: 0.75em; }
    h6 { font-size: 0.5em; }
    h7 { font-size: 0.3em; }

    ul {
        margin-block-start: 0.2em;
    }

    ul > li {
        margin-bottom: 0.2em;
    }

    p + h1,
    p + h2,
    p + h3,
    p + h4,
    p + h5,
    p + h6,
    p + h7 {
        margin-top: 0.75em;
    }
}

img.image-full-bleed {
    width: 100vw;
}

img.image-fill-width {
    width: 100%;
}

/* Size embedded YouTube iframes in a sensible way */
p > iframe {
    aspect-ratio: 16 / 9;
    height: 100%;
    width: 100%;
}

.attribution-container {
    margin-top: 0px;
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0px;
    }

    h3 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    p {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.footnotes {
    font-size: 14px;
}